/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Nav, NavItem, NavLink } from "reactstrap";

function Footer() {
    return (
        <footer className="footer">
            <Container fluid>
                <Nav>
                    <NavItem>
                        <NavLink href="#">documentation</NavLink>
                    </NavItem>
                </Nav>
                <div className="copyright">
                    © {new Date().getFullYear()} SmartProxy - All Rights
                </div>
            </Container>
        </footer>
    );
}

export default Footer;
