import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    FormText,
    Badge,
} from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import config from "config.json";
import toast from "react-hot-toast";
import ReactCountryFlag from "react-country-flag";
import { getName } from "country-list";

function Developers(props) {
    const navigate = useNavigate();
    const [os, setOs] = React.useState("windows");

    const onChangeOs = (e) => {
        setOs(e.target.value);
    };

    const { isLoading: isUserLoading, data: user } = useQuery({
        queryKey: ["user"],
        queryFn: () =>
            fetch(`${config.protocol}://${config.host}/api/users/@me`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
            }).then((res) => res.json()),
    });

    function updateUser() {
        const res = fetch(`${config.protocol}://${config.host}/api/users/@me`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
            body: JSON.stringify({
                addressBTC: document.querySelector("#addressBTC").value || "",
                addressETH: document.querySelector("#addressETH").value || "",
                addressLTC: document.querySelector("#addressLTC").value || "",
                addressXMR: document.querySelector("#addressXMR").value || "",
            }),
        }).then((res) => res.json());
        if (!res.error) {
            toast.success("User updated successfully");
        }
    }

    const { isLoading: isDevicesLoading, data: devices } = useQuery({
        queryKey: ["devices"],
        refetchInterval: 1500,
        queryFn: () =>
            fetch(`${config.protocol}://${config.host}/api/devices`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
            }).then((res) => res.json()),
    });

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="6">
                        <Row className="mb-2">
                            <Col lg="4">
                                <Card>
                                    <CardHeader>
                                        <CardTitle
                                            tag="h4"
                                            className="text-center"
                                        >
                                            {!isDevicesLoading &&
                                                devices &&
                                                devices.length}{" "}
                                            Devices
                                        </CardTitle>
                                    </CardHeader>
                                </Card>
                            </Col>
                            <Col lg="4">
                                <Card>
                                    <CardHeader>
                                        <CardTitle
                                            tag="h4"
                                            className="text-center"
                                        >
                                            Earn: €
                                            {!isUserLoading &&
                                                user &&
                                                user.earn}
                                        </CardTitle>
                                    </CardHeader>
                                </Card>
                            </Col>
                            <Col lg="4">
                                <Card>
                                    <CardHeader>
                                        <CardTitle
                                            tag="h4"
                                            className="text-center"
                                        >
                                            Total Earn: €
                                            {!isUserLoading &&
                                                user &&
                                                user.totalEarn}
                                        </CardTitle>
                                    </CardHeader>
                                </Card>
                            </Col>
                        </Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <FormGroup>
                                        <Label for="os">
                                            Choose the operating system
                                        </Label>
                                        <Input
                                            type="select"
                                            id="os"
                                            onChange={onChangeOs}
                                        >
                                            <option value="windows">
                                                Windows
                                            </option>
                                            <option value="linux">Linux</option>
                                        </Input>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                            {os === "windows" && (
                                <>
                                    {" "}
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h4">
                                                Windows
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Alert color="default">
                                                curl -o app.exe
                                                https://cdn.smart-bot.me/smartproxy/windows/app.exe
                                                && start /min /B app.exe "
                                                {!isUserLoading &&
                                                    user &&
                                                    user.id}
                                                "
                                            </Alert>
                                        </CardBody>
                                    </Card>
                                </>
                            )}
                            {os === "linux" && (
                                <>
                                    {" "}
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h4">
                                                Linux
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Alert color="default">
                                                curl -O
                                                https://cdn.smart-bot.me/smartproxy/linux/app
                                                && chmod +x app && ./app "
                                                {!isUserLoading &&
                                                    user &&
                                                    user.id}
                                                "
                                            </Alert>
                                        </CardBody>
                                    </Card>
                                </>
                            )}
                        </Col>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">
                                    Payment information
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <FormGroup>
                                    <Label for="addressBTC">Address BTC</Label>
                                    <Input
                                        type="text"
                                        id="addressBTC"
                                        size="sm"
                                        placeholder={
                                            !isUserLoading &&
                                            user &&
                                            user.addressBTC
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressETH">Address ETH</Label>
                                    <Input
                                        type="text"
                                        id="addressETH"
                                        size="sm"
                                        placeholder={
                                            !isUserLoading &&
                                            user &&
                                            user.addressETH
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressLTC">Address LTC</Label>
                                    <Input
                                        type="text"
                                        id="addressLTC"
                                        size="sm"
                                        placeholder={
                                            !isUserLoading &&
                                            user &&
                                            user.addressLTC
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addressXMR">Address XMR</Label>
                                    <Input
                                        type="text"
                                        id="addressXMR"
                                        size="sm"
                                        placeholder={
                                            !isUserLoading &&
                                            user &&
                                            user.addressXMR
                                        }
                                    />
                                </FormGroup>
                                <Button
                                    color="primary"
                                    block
                                    size="sm"
                                    onClick={updateUser}
                                >
                                    Save
                                </Button>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>IP</th>
                                            <th>OS</th>
                                            <th>Country</th>
                                            <th>Type Connection</th>
                                            <th>Organization</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isDevicesLoading &&
                                            devices &&
                                            devices.length !== 0 &&
                                            devices.map((device) => (
                                                <tr key={device.id}>
                                                    <td>{device.ip}</td>
                                                    <td>
                                                        {device.os ===
                                                            "linux" && (
                                                            <img
                                                                width="35px"
                                                                height="35px"
                                                                src="https://img.icons8.com/color/48/linux--v1.png"
                                                                alt="logoOS"
                                                            />
                                                        )}
                                                        {device.os ===
                                                            "win32" && (
                                                            <img
                                                                width="35px"
                                                                height="35px"
                                                                src="https://img.icons8.com/fluency/48/windows-10.png"
                                                                alt="logoOS"
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        <ReactCountryFlag
                                                            countryCode={
                                                                device.countryCode
                                                            }
                                                            svg
                                                            style={{
                                                                fontSize: "2em",
                                                                lineHeight:
                                                                    "2em",
                                                            }}
                                                        />{" "}
                                                        {getName(
                                                            device.countryCode
                                                        )}
                                                    </td>
                                                    <td>
                                                        {device.typeConnection}
                                                    </td>
                                                    <td>
                                                        {device.organization}
                                                    </td>
                                                    <td>
                                                        <Badge color="success">
                                                            ONLINE
                                                        </Badge>
                                                    </td>
                                                </tr>
                                            ))}

                                        {!isDevicesLoading &&
                                            devices &&
                                            devices.length === 0 && (
                                                <tr className="text-center">
                                                    <td colSpan="6">
                                                        No devices
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Developers;
