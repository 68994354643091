import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Badge,
} from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import config from "config.json";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.css";

function Dashboard(props) {
    const navigate = useNavigate();

    const { isLoading: isStatLoading, data: stat } = useQuery({
        queryKey: ["stat"],
        refetchInterval: 1500,
        queryFn: () =>
            fetch(`${config.protocol}://${config.host}/api/stat`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
            }).then((res) => res.json()),
    });

    useEffect(() => {
        if (!stat) return;
        document.getElementById("map").innerHTML = "";
        const map = new jsVectorMap({
            selector: "#map",
            map: "world",
            markers: [
                {
                    name: "Relay",
                    coords: [49.7902, 6.0856],
                    style: { fill: "#e84768" },
                },
                ...stat?.devices.map((device) => ({
                    name: device.id,
                    coords: [device.latitude, device.longitude],
                })),
            ],
            lines: stat?.devices.map((device) => ({
                to: "Relay",
                from: device.id,
            })),
            lineStyle: {
                stroke: "#676767",
                strokeWidth: 1.5,
                fill: "#ff5566",
                fillOpacity: 1,
                strokeDasharray: "6 2 6",
                animation: true, // Enables animation
            },
        });
    }, [stat]);

    return (
        <>
            <div className="content">
                <Row className="mt-5">
                    <Col md="8">
                        <h3 className="title">Welcome Anonymous 👋</h3>
                        <Card>
                            <CardBody>
                                <div
                                    id="map"
                                    style={{
                                        position: "sticky",
                                        top: "0",
                                        width: "100%",
                                        minHeight: "500px",
                                    }}
                                ></div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <h3 className="title">
                            Account{" "}
                            <span className="text-primary">Overview</span>
                        </h3>

                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div
                                    style={{
                                        background: "#27293d",
                                        minWidth: "50px",
                                        minHeight: "50px",
                                        borderRadius: "5px",
                                    }}
                                    className="d-flex justify-content-center align-items-center"
                                >
                                    <i className="fa-solid fa-users fa-2x text-light" />
                                </div>
                                <div className="d-flex align-items-sm-end flex-column">
                                    <h3 className="m-0 text-primary title">
                                        {!isStatLoading && stat?.users}
                                    </h3>
                                    <small>Users</small>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div
                                    style={{
                                        background: "#27293d",
                                        minWidth: "50px",
                                        minHeight: "50px",
                                        borderRadius: "5px",
                                    }}
                                    className="d-flex justify-content-center align-items-center"
                                >
                                    <i className="fa-solid fa-server fa-2x text-light" />
                                </div>
                                <div className="d-flex align-items-sm-end flex-column">
                                    <h3 className="m-0 text-primary title">
                                        {!isStatLoading && stat?.datacenters}{" "}
                                        {!isStatLoading &&
                                            stat?.shareholdingDatacenters !==
                                                0 &&
                                            "(" +
                                                stat?.shareholdingDatacenters +
                                                "%)"}
                                    </h3>
                                    <small>Datacenters Proxies</small>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div
                                    style={{
                                        background: "#27293d",
                                        minWidth: "50px",
                                        minHeight: "50px",
                                        borderRadius: "5px",
                                    }}
                                    className="d-flex justify-content-center align-items-center"
                                >
                                    <i className="fa-solid fa-house fa-2x text-light" />
                                </div>
                                <div className="d-flex align-items-sm-end flex-column">
                                    <h3 className="m-0 text-primary title">
                                        {!isStatLoading && stat?.residentials}{" "}
                                        {!isStatLoading &&
                                            stat?.shareholdingResidentials !==
                                                0 &&
                                            "(" +
                                                stat?.shareholdingResidentials +
                                                "%)"}
                                    </h3>
                                    <small>Residential Proxies</small>
                                </div>
                            </div>
                        </div>
                        <Card>
                            <CardBody>
                                <div className="mb-4">
                                    <p>
                                        You are looking for good quality proxies
                                        ?
                                    </p>
                                    <small>
                                        You are tired of payers at providers who
                                        provide you ips at abusive prices or in
                                        the case of residential ip ips of
                                        unknown internet operator to the
                                        battalion, well choose us,{" "}
                                        <b>SmartProxy</b> only integrates
                                        internet operators known for these
                                        residential proxies, the rest of the ips
                                        and add in the datacenter category that
                                        is accessible at reasonable prices.
                                    </small>
                                </div>
                                <div className="mb-4">
                                    <p>
                                        Can I integrate your application into my
                                        software without warning users ?
                                    </p>
                                    <small>
                                        You are{" "}
                                        <u>obliged to notify your users</u> that
                                        you will use their IP address for
                                        remuneration purposes from us if you do
                                        not,{" "}
                                        <u>
                                            your account will be suspended and
                                            your user removed from our network
                                        </u>
                                        . (abuse@smart-bot.me)
                                    </small>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Dashboard;
