import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import {
    Button,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Col,
    FormText,
    Label,
    Input,
} from "reactstrap";

import { toast } from "react-hot-toast";

const Register = ({ config }) => {
    const navigate = useNavigate();

    function login() {
        if (!document.getElementById("username").value) {
            toast.error("Username is required.");
            return;
        }

        if (!document.getElementById("password").value) {
            toast.error("Password is required.");
            return;
        }

        if (
            document.getElementById("password").value !==
            document.getElementById("repassword").value
        ) {
            toast.error("Passwords do not match.");
            return;
        }

        fetch(`${config.protocol}://${config.host}/api/auth/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: document.getElementById("username").value,
                password: document.getElementById("password").value,
            }),
        }).then((res) => {
            res.json().then((data) => {
                if (data.error) {
                    toast.error(data.error);
                } else {
                    toast.success("Logged in successfully.");
                    localStorage.setItem("token", data.token);
                    localStorage.setItem("userId", data.userId);
                    navigate("/_/");
                }
            });
        });
    }

    return (
        <div
            className="d-flex justify-content-center"
            style={{ margin: "200px 0px 0px 0px" }}
        >
            <Col lg="4">
                <Card>
                    <CardBody>
                        <CardTitle className="text-center" tag="h3">
                            Register
                        </CardTitle>
                        <FormGroup>
                            <Label for="username">Username</Label>
                            <Input type="text" id="username" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input type="password" id="password" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="repassword">Confirm Password</Label>
                            <Input type="password" id="repassword" />
                        </FormGroup>
                        <Button color="primary" block onClick={() => login()}>
                            Register
                        </Button>
                        <FormText color="muted">
                            Already have an account?{" "}
                            <Link to="/login">Login</Link>
                        </FormText>
                    </CardBody>
                </Card>
            </Col>
        </div>
    );
};

export default Register;
