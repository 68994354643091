/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    NavItem,
    Container,
    Modal,
    NavbarToggler,
    ModalHeader,
} from "reactstrap";
import logo from "assets/img/logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import config from "config.json";

function AdminNavbar(props) {
    const [collapseOpen, setcollapseOpen] = React.useState(false);
    const [modalSearch, setmodalSearch] = React.useState(false);
    const [color, setcolor] = React.useState("navbar-transparent");

    const navigate = useNavigate();

    React.useEffect(() => {
        window.addEventListener("resize", updateColor);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.removeEventListener("resize", updateColor);
        };
    });
    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && collapseOpen) {
            setcolor("bg-white");
        } else {
            setcolor("navbar-transparent");
        }
    };
    // this function opens and closes the collapse on small devices
    const toggleCollapse = () => {
        if (collapseOpen) {
            setcolor("navbar-transparent");
        } else {
            setcolor("bg-white");
        }
        setcollapseOpen(!collapseOpen);
    };
    // this function is to open the Search modal
    const toggleModalSearch = () => {
        setmodalSearch(!modalSearch);
    };

    const location = useLocation();

    const [data, setData] = React.useState({});
    React.useEffect(() => {
        fetch(`${config.protocol}://${config.host}/api/users/@me`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        }).then((res) => {
            res.json().then((data) => {
                if (!data.error) {
                    setData(data);
                    localStorage.setItem(
                        "subscriptions",
                        JSON.stringify(data.subscriptions)
                    );
                }
            });
        });
    }, [location]);

    return (
        <>
            <Navbar
                className={classNames("navbar-absolute", color)}
                expand="lg"
            >
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div
                            className={classNames("navbar-toggle d-inline", {
                                toggled: props.sidebarOpened,
                            })}
                        >
                            <NavbarToggler onClick={props.toggleSidebar}>
                                <span className="navbar-toggler-bar bar1" />
                                <span className="navbar-toggler-bar bar2" />
                                <span className="navbar-toggler-bar bar3" />
                            </NavbarToggler>
                        </div>
                        <NavbarBrand
                            href="#"
                            onClick={(e) => e.preventDefault()}
                        >
                            <img
                                src={logo}
                                alt="logo"
                                className="mr-2"
                                width="35px"
                            />
                            SmartProxy (1.3)
                        </NavbarBrand>
                    </div>
                    <NavbarToggler onClick={toggleCollapse}>
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                    </NavbarToggler>
                    <Collapse navbar isOpen={collapseOpen}>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <Button
                                    color="link"
                                    onClick={() => navigate("/_/plans")}
                                ></Button>
                                <Button
                                    color="link"
                                    className="text-white"
                                    onClick={() => {
                                        localStorage.clear();
                                        navigate("/");
                                    }}
                                >
                                    <i className="fa-solid fa-right-from-bracket" />
                                </Button>
                            </NavItem>
                            <li className="separator d-lg-none" />
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default AdminNavbar;
