import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    FormText,
} from "reactstrap";

import { toast } from "react-hot-toast";

const Login = ({ config }) => {
    const navigate = useNavigate();

    function login() {
        if (!document.getElementById("username").value) {
            toast.error("Username is required.");
            return;
        }

        if (!document.getElementById("password").value) {
            toast.error("Password is required.");
            return;
        }

        fetch(`${config.protocol}://${config.host}/api/auth/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: document.getElementById("username").value,
                password: document.getElementById("password").value,
            }),
        }).then((res) => {
            res.json().then((data) => {
                if (data.error) {
                    toast.error(data.error);
                } else {
                    toast.success("Logged in successfully.");
                    localStorage.setItem("token", data.token);
                    localStorage.setItem("userId", data.userId);
                    navigate("/_/");
                }
            });
        });
    }

    document.addEventListener("keypress", (e) => {
        if (e.key === "Enter") {
            login();
        }
    });

    return (
        <div
            className="d-flex justify-content-center"
            style={{ margin: "200px 0px 0px 0px" }}
        >
            <Col lg="4">
                <Card>
                    <CardBody>
                        <CardTitle className="text-center" tag="h3">
                            Login
                        </CardTitle>
                        <FormGroup>
                            <Label for="username">Username</Label>
                            <Input type="text" id="username" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input type="password" id="password" />
                        </FormGroup>
                        <Button color="primary" block onClick={() => login()}>
                            Login
                        </Button>
                        <FormText color="muted">
                            Don't have an account?{" "}
                            <Link to="/register">Register</Link>
                        </FormText>
                    </CardBody>
                </Card>
            </Col>
        </div>
    );
};

export default Login;
