import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = (props) => {
    const navigate = useNavigate();

    const [isAuth, setIsAuth] = useState(false);
    fetch(`${props.config.protocol}://${props.config.host}/api/auth/verify`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
        },
    }).then((res) => {
        res.json().then((data) => {
            if (!data.id) {
                navigate("/login");
            } else {
                setIsAuth(true);
            }
        });
    });

    return isAuth ? props.component : null;
};

export default ProtectedRoute;
