import React from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
} from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import config from "config.json";
import toast from "react-hot-toast";
import ReactCountryFlag from "react-country-flag";
import { getName } from "country-list";

function Proxies(props) {
    const { typeConnection } = useParams();
    const [proxiesFiltered, setProxiesFiltered] = React.useState(false);

    const { isLoading: isProxiesLoading, data: proxies } = useQuery({
        queryKey: ["proxies"],
        refetchInterval: 1500,
        queryFn: () =>
            fetch(
                `${config.protocol}://${config.host}/api/proxies/${typeConnection}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("token"),
                    },
                }
            ).then((res) => res.json()),
    });

    const {
        isLoading: isWhitelistLoading,
        data: whitelist,
        refetch: reFetchWhitelist,
    } = useQuery({
        queryKey: ["whitelist"],
        refetchInterval: 1500,
        queryFn: () =>
            fetch(
                `${config.protocol}://${config.host}/api/whitelist/${typeConnection}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("token"),
                    },
                }
            ).then((res) => res.json()),
    });

    const addWhitelist = () => {
        const ip = document.getElementById("ipwhitelist").value;
        fetch(
            `${config.protocol}://${config.host}/api/whitelist/${typeConnection}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
                body: JSON.stringify({ ip }),
            }
        ).then((res) => {
            res.json().then((data) => {
                if (!data.error) {
                    toast.success("IP added to whitelist");
                    reFetchWhitelist();
                    document.getElementById("ipwhitelist").value = "";
                } else {
                    toast.error(data.error);
                }
            });
        });
    };

    function removeWhitelist(id) {
        fetch(
            `${config.protocol}://${config.host}/api/whitelist/${typeConnection}/${id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
            }
        ).then((res) => {
            res.json().then((data) => {
                if (!data.error) {
                    toast.success("IP removed from whitelist");
                    reFetchWhitelist();
                } else {
                    toast.error(data.error);
                }
            });
        });
    }

    if (typeConnection !== "residential" && typeConnection !== "datacenter") {
        toast.error("Invalid proxy type");
        return <div className="content"></div>;
    }

    const handleChangeCountryCode = (event) => {
        setProxiesFiltered(event.target.value);
    };

    const downloadProxies = () => {
        const proxyList = proxies
            .filter(
                (item) =>
                    !proxiesFiltered || item.countryCode === proxiesFiltered
            )
            .map((proxy) => `107.189.30.124:${proxy.portProxy}`)
            .join("\n");
        const element = document.createElement("a");
        const file = new Blob([proxyList], { type: "text/plain" });
        element.href = URL.createObjectURL(file);
        element.download = "proxy_list.txt";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="8">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">
                                    Proxies (
                                    {typeConnection.toLocaleUpperCase()})
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row className="d-flex align-items-center">
                                    <Col lg="8">
                                        <FormGroup>
                                            <Label>Search by</Label>
                                            <Input
                                                type="select"
                                                onChange={
                                                    handleChangeCountryCode
                                                }
                                            >
                                                <option value="">All</option>
                                                {!isProxiesLoading &&
                                                    proxies.length > 0 &&
                                                    proxies &&
                                                    Array.from(
                                                        new Set(
                                                            proxies.map(
                                                                (proxy) =>
                                                                    proxy.countryCode
                                                            )
                                                        )
                                                    ).map((countryCode) => (
                                                        <option
                                                            key={countryCode}
                                                        >
                                                            {countryCode}
                                                        </option>
                                                    ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <Button
                                            onClick={downloadProxies}
                                            size="sm"
                                        >
                                            Download Connected devices (
                                            {proxies &&
                                                proxies.length > 0 &&
                                                proxies.filter(
                                                    (item) =>
                                                        !proxiesFiltered ||
                                                        item.countryCode ===
                                                            proxiesFiltered
                                                ).length}
                                            )
                                        </Button>
                                    </Col>
                                </Row>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>IP Relay</th>
                                            <th>Real IP</th>
                                            <th>Country</th>
                                            <th>Organization</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isProxiesLoading &&
                                            proxies &&
                                            proxies.length > 0 &&
                                            proxies
                                                .filter(
                                                    (item) =>
                                                        !proxiesFiltered ||
                                                        item.countryCode ===
                                                            proxiesFiltered
                                                )
                                                .map((proxy) => (
                                                    <tr key={proxy.id}>
                                                        <td>
                                                            {"107.189.30.124" +
                                                                ":" +
                                                                proxy.portProxy}
                                                        </td>
                                                        <td>{proxy.ip}</td>
                                                        <td>
                                                            <ReactCountryFlag
                                                                countryCode={
                                                                    proxy.countryCode
                                                                }
                                                                style={{
                                                                    fontSize:
                                                                        "2em",
                                                                    lineHeight:
                                                                        "2em",
                                                                }}
                                                                svg
                                                            />{" "}
                                                            {getName(
                                                                proxy.countryCode
                                                            )}
                                                        </td>
                                                        <td>
                                                            {proxy.organization}
                                                        </td>
                                                    </tr>
                                                ))}
                                        {!isProxiesLoading &&
                                            proxies &&
                                            proxies.length === 0 && (
                                                <tr>
                                                    <td
                                                        colSpan="4"
                                                        className="text-center"
                                                    >
                                                        No connected devices
                                                        (This may be due at
                                                        present in the country.)
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card>
                            <CardBody>
                                <h3>Whitelist IP</h3>
                                <div>
                                    {!isWhitelistLoading &&
                                        whitelist &&
                                        whitelist.length > 0 &&
                                        whitelist.map((item) => (
                                            <FormGroup
                                                className="d-flex align-self-center"
                                                key={item.id}
                                            >
                                                <Input
                                                    type="input"
                                                    value={item.ip}
                                                    style={{
                                                        borderTopRightRadius: 0,
                                                        borderBottomRightRadius: 0,
                                                    }}
                                                    disabled
                                                />
                                                <Button
                                                    color="danger"
                                                    size="sm"
                                                    className="m-0"
                                                    style={{
                                                        borderTopLeftRadius: 0,
                                                        borderBottomLeftRadius: 0,
                                                    }}
                                                    onClick={() =>
                                                        removeWhitelist(item.id)
                                                    }
                                                >
                                                    x
                                                </Button>
                                            </FormGroup>
                                        ))}
                                    <FormGroup className="d-flex align-self-center">
                                        <Input
                                            type="input"
                                            placeholder="1.3.3.7"
                                            id="ipwhitelist"
                                            style={{
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0,
                                            }}
                                        />
                                        <Button
                                            color="success"
                                            size="sm"
                                            className="m-0"
                                            style={{
                                                borderTopLeftRadius: 0,
                                                borderBottomLeftRadius: 0,
                                            }}
                                            onClick={addWhitelist}
                                        >
                                            +
                                        </Button>
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Proxies;
