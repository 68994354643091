import { useState } from "react";
import { Link } from "react-router-dom";

import {
    Button,
    NavItem,
    Navbar,
    Container,
    Collapse,
    Nav,
    NavbarToggler,
    NavbarBrand,
} from "reactstrap";

import "./styles.scss";
import imgLogo from "../../assets/img/logo.png";

const Landing = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Navbar expand="lg" color="transparent">
                <Container>
                    <NavbarBrand
                        className="position-relative d-flex align-items-center"
                        href="https://proxy.smart-bot.me"
                    >
                        <img src={imgLogo} alt="logo SmartBot" width="50px" />
                        <h2 className="m-0 title">SmartProxy</h2>
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle}>
                        <span className="navbar-toggler-bar"></span>
                        <span className="navbar-toggler-bar"></span>
                        <span className="navbar-toggler-bar"></span>
                    </NavbarToggler>
                    <Collapse isOpen={isOpen} navbar>
                        <Nav navbar className="ml-auto">
                            <NavItem>
                                <Link to="/register" className="nav-link">
                                    <Button color="primary">Register</Button>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/login" className="nav-link">
                                    <Button color="primary">Login</Button>
                                </Link>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>

            <div className="d-flex justify-content-center align-items-center my-xl">
                <div className="d-flex flex-column align-items-center">
                    <h1 className="title">SmartProxy</h1>
                    <h3>Provider of residential proxies and datacenter</h3>
                </div>
            </div>

            <section
                className="section mb-5"
                style={{ backgroundColor: "#181824" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 mb-5">
                            <h4>
                                <i className="fa-solid fa-ban mr-2" />
                                No logs
                            </h4>
                            <p>We do not log on our servers</p>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-5">
                            <h4>
                                <i className="fa-solid fa-bolt mr-2" />
                                Super Fast
                            </h4>
                            <p>
                                Our servers have low latency and high throughput
                            </p>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-5">
                            <h4>
                                <i className="fa-solid fa-money-bill mr-2" />
                                Earn money
                            </h4>
                            <p>
                                You can participate in our proxy network and get
                                a monthly gain
                            </p>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <h4>
                                <i className="fa-solid fa-medal mr-2" />
                                Quality IP
                            </h4>
                            <p>
                                Our residential proxies come only from known
                                internet provider, we do not accept residential
                                AS that can be considered as robot
                            </p>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-5">
                            <h4>
                                <i className="fa-solid fa-file-shield mr-2" />
                                Security
                            </h4>
                            <p>
                                You are not directly connected to our users, you
                                go through a relay server that manages the
                                secure connection
                            </p>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-5">
                            <h4>
                                <i className="fa-solid fa-envelope mr-2" />
                                Multiplatform Support
                            </h4>
                            <p>
                                You can contact us by Email or Telegram (We can
                                switch to Discord if you make the request)
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="my-2">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 text-center">
                            <img
                                src={imgLogo}
                                alt="logo SmartBot"
                                width="50px"
                            />
                            <h2 className="m-0 title">SmartProxy</h2>
                        </div>
                        <div className="col-sm-3">
                            <h4>Support Social Network</h4>
                            <ul className="list-unstyled ml-1">
                                <li>
                                    <Link to="https://t.me/MrJamesSmartBot">
                                        <i className="fa-brands fa-telegram"></i>{" "}
                                        Telegram
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-3">
                            <h4>Support</h4>
                            <ul className="list-unstyled ml-1">
                                <Link to="mailto:contact@smart-bot.me">
                                    contact@smart-bot.me
                                </Link>
                            </ul>
                        </div>
                        <div className="col-sm-3">
                            <ul className="list-unstyled ml-1">
                                <h4>Abuse</h4>
                                <li>
                                    <Link to="mailto:abuse@smart-bot.me">
                                        abuse@smart-bot.me
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="text-center mt-4">
                        <small className="text-muted">
                            © All Rights Reserved Smart-Bot
                        </small>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Landing;
