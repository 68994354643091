import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import config from "./config.json";

import ProtectedRoute from "./ProtectedRoute.jsx";

import Landing from "./views/Landing/Landing.jsx";
import Register from "./auth/Register/Register.jsx";
import Login from "./auth/Login/Login.jsx";

import AdminLayout from "layouts/Admin/Admin.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/custom.scss";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
    <QueryClientProvider client={queryClient}>
        <ThemeContextWrapper>
            <BackgroundColorWrapper>
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/_/*"
                            element={
                                <ProtectedRoute
                                    config={config}
                                    component={<AdminLayout />}
                                />
                            }
                        />
                        <Route path="/" element={<Landing />} />
                        <Route
                            path="/register"
                            element={<Register config={config} />}
                        />
                        <Route
                            path="/login"
                            element={<Login config={config} />}
                        />
                    </Routes>
                </BrowserRouter>
                <Toaster
                    position="top-center"
                    toastOptions={{
                        style: {
                            background: "#27293d",
                            border: "2px solid #2c7bbe",
                            color: "white",
                        },
                    }}
                />
            </BackgroundColorWrapper>
        </ThemeContextWrapper>
    </QueryClientProvider>
);
