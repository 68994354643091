import Dashboard from "views/Dashboard.jsx";
import Plans from "views/Plans";
import Proxies from "views/Proxies";
import Developers from "views/Developers";

const routes = [
    {
        path: "/",
        name: "Dashboard",
        icon: "fa-solid fa-chart-line",
        component: <Dashboard />,
        layout: "/_",
    },
    {
        path: "/plans",
        name: "Get your access",
        icon: "fa-solid fa-star text-warning",
        component: <Plans />,
        layout: "/_",
    },
    {
        path: "/proxies/:typeConnection",
        name: "Proxies",
        icon: "fa-solid fa-network-wired",
        component: <Proxies />,
        layout: "/_",
        redirect: true,
    },
    {
        path: "/developers",
        name: "For Developers",
        icon: "fa-solid fa-code",
        component: <Developers />,
        layout: "/_",
    },
];

export default routes;
